import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!username || !password) {
      console.error("Username and password are required");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/userapi/login/`,
        {
          username,
          password,
        },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        console.log("Login successful");
        navigate("/");
      } else {
        alert(
          "Login failed. Try again or create an account if you do not have one."
        );
      }
    } catch (error) {
      alert(
        "Login failed. Try again or create an account if you do not have one."
      );
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit}>
        <h1 className="pacifico-regular">March Madness Login</h1>
        <input
          type="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          autoComplete="username"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          autoComplete="current-password"
        />
        <button type="submit">Login</button>
      </form>
      <Link className="switch-signup" to="/signup">
        Don't have an account? Sign up here.
      </Link>
    </div>
  );
};

export default Login;
