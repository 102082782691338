import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// Import your components
import Signup from "./components/signup"; // Adjust the path as necessary
import Login from "./components/login"; // Adjust the path as necessary
import BracketList from "./components/bracket_list"; // Adjust the path as necessary
import BracketMaker from "./components/bracket_creator";
import BracketDisplay from "./components/bracket_display";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<BracketList />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/create-bracket" element={<BracketMaker />} />
        <Route path="/bracket/:id" element={<BracketDisplay />} />
      </Routes>
    </Router>
  );
};

export default App;
