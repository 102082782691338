import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Cookies } from "react-cookie";

const Navbar = () => {
  const [fullName, setFullName] = useState("");

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/userapi/check-auth/`,
          {
            withCredentials: true,
          }
        );
        if (response.status === 200) setFullName(response.data.full_name);
      } catch (error) {
        console.error("Error checking auth status", error);
      }
    };
    checkAuth();
  }, []);

  const handleLogout = async () => {
    try {
      const csrfToken = new Cookies().get("csrftoken");
      console.log("csrfToken", csrfToken);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/userapi/logout/`,
        null,
        { withCredentials: true, headers: { "X-CSRFTOKEN": csrfToken } }
      );
      if (response.status === 200) {
        setFullName("");
      }
    } catch (error) {
      console.error("Error logging out", error);
    }
  };

  return (
    <nav>
      <Link className="pacifico-regular nav-link" to="/">
        Tournament Home
      </Link>
      {!fullName && (
        <Link className="nav-link" to="/login">
          Login or Signup
        </Link>
      )}
      {fullName && (
        <div>
          <p>Welcome, {fullName}</p>
          <button onClick={handleLogout}>Logout</button>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
