import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Cookies } from "react-cookie";
import { Game } from "../../types/custom_types";
import Navbar from "./navbar";
import Footer from "./footer";
import Loading from "./Loading/Loading";
import {
  Bracket,
  IRoundProps,
  IRenderSeedProps,
  SingleLineSeed,
  SeedTeam,
  SeedItem,
  Seed,
} from "react-brackets";

const BracketMaker = () => {
  const navigate = useNavigate();

  const [games, setGames] = useState<Game[]>([]);
  const [rounds, setRounds] = useState<IRoundProps[]>([]);
  const [firstFour, setFirstFour] = useState<IRoundProps[]>([]);
  const [loading, setLoading] = useState(true);

  const roundTitles = [
    "First Four",
    "Round 1",
    "Round 2",
    "Sweet 16",
    "Elite 8",
    "Final 4",
    "Championship",
  ];

  // Check if the user is logged in
  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/userapi/check-auth/`,
          {
            withCredentials: true,
          }
        );
        if (response.status !== 200) {
          navigate("/login");
        }
      } catch (error) {
        navigate("/login");
      }
    };

    checkLoginStatus();

    // Get the csrf token from the cookie
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchGames = async () => {
      const response = await axios.get<Game[]>(
        `${process.env.REACT_APP_API_URL}/userapi/games/`
      );
      setGames(response.data);
    };

    fetchGames();
  }, []);

  useEffect(() => {
    if (games.length === 0) {
      return;
    }

    const firstFourGames = games.filter((game) => game.round === 0);
    const firstFourSeeds = firstFourGames.map((game) => {
      return {
        id: game.id,
        teams: [
          {
            name: game.team1_name,
            isWinner: game.winner_name === game.team1_name,
            seed: game.team1_seed,
            wins: game.team1_wins,
            losses: game.team1_losses,
          },
          {
            name: game.team2_name,
            isWinner: game.winner_name === game.team2_name,
            seed: game.team2_seed,
            wins: game.team2_wins,
            losses: game.team2_losses,
          },
        ],
      };
    });
    setFirstFour([
      {
        title: "First Four",
        seeds: firstFourSeeds,
      },
    ]);

    // Create a list of rounds based on the games
    const roundMap = new Map<number, Game[]>();
    games.forEach((game) => {
      if (game.round === 0) {
        return;
      }
      if (!roundMap.has(game.round)) {
        roundMap.set(game.round, []);
      }
      roundMap.get(game.round)?.push(game);
    });

    const rounds = Array.from(roundMap.entries()).map(([round, games]) => {
      return {
        title: roundTitles[round],
        seeds: games.map((game) => {
          return {
            id: game.id,
            teams: [
              {
                name: game.team1_name,
                isWinner:
                  game.winner_name && game.team1_name === game.winner_name,
                seed: game.team1_seed,
                wins: game.team1_wins,
                losses: game.team1_losses,
              },
              {
                name: game.team2_name,
                isWinner:
                  game.winner_name && game.team2_name === game.winner_name,
                seed: game.team2_seed,
                wins: game.team2_wins,
                losses: game.team2_losses,
              },
            ],
          };
        }),
      };
    });

    setRounds(rounds);
    setLoading(false);
  }, [games]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectWinner = (
    selectedGame: Game,
    winnerTeam: "team1_name" | "team2_name"
  ) => {
    // Create a deep copy of the games array to prevent direct state mutation
    let updatedGames = games.map((game) => ({ ...game }));

    // Update the winner for the selected game
    updatedGames = updatedGames.map((game) => {
      if (game.id === selectedGame.id) {
        return { ...game, winner_name: selectedGame[winnerTeam] };
      }
      return game;
    });

    // Get the other team name
    const otherTeam = winnerTeam === "team1_name" ? "team2_name" : "team1_name";
    const otherTeamName = selectedGame[otherTeam];

    const clearFutureWinners = (round: number, updatedGames: Game[]): void => {
      updatedGames.forEach((game) => {
        if (game.round <= round) {
          return;
        }

        // Clear the winner name and team assignments for games that depend on the other team
        if (game.team1_name === otherTeamName) {
          game.team1_name = undefined;
        } else if (game.team2_name === otherTeamName) {
          game.team2_name = undefined;
        }
        if (game.winner_name === otherTeamName) {
          game.winner_name = undefined;
        }
      });
    };

    // If the selection has changed, clear future winners and their team assignments
    if (selectedGame.winner_name !== selectedGame[winnerTeam]) {
      clearFutureWinners(selectedGame.round, updatedGames);
    }

    // Propagate the new winner to the next round, if applicable
    updatedGames.forEach((game) => {
      if (game.previous_game_for_team1_id === selectedGame.id) {
        game.team1_name = selectedGame[winnerTeam];
      } else if (game.previous_game_for_team2_id === selectedGame.id) {
        game.team2_name = selectedGame[winnerTeam];
      }
    });

    // Update the state with the modified games array
    setGames(updatedGames);
  };

  const handlePartyClick = (game_id: number, party_won: "team1" | "team2") => {
    // Get game by game_id
    const game = games.find((game) => game.id === game_id);
    if (!game) {
      return;
    }
    handleSelectWinner(
      game,
      party_won === "team1" ? "team1_name" : "team2_name"
    );
  };

  const handleSubmit = async () => {
    // Check if all games have a winner
    const allGamesHaveWinner = games.every((game) => game.winner_name);
    if (!allGamesHaveWinner) {
      alert("Not all games have a winner yet.");
      return;
    }

    // Check if the name input is empty
    const bracketName = (
      document.querySelector("input[type=text]") as HTMLInputElement
    )?.value;
    if (!bracketName) {
      alert("Please enter a bracket name.");
      return;
    }

    // Get a list of all the predictions in the format required by the API
    // {game_id, predicted_team1, predicted_team2, predicted_winner}
    const predictions = games.map((game) => ({
      game_id: game.id,
      predicted_team1: game.team1_name,
      predicted_team2: game.team2_name,
      predicted_winner: game.winner_name,
    }));

    // Send the predictions to the API
    try {
      console.log("sending predictions to the API");
      const csrfToken = new Cookies().get("csrftoken");
      console.log("csrfToken", csrfToken);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/userapi/predictions/`,
        {
          bracket_name: bracketName,
          predictions,
        },
        { withCredentials: true, headers: { "X-CSRFTOKEN": csrfToken } }
      );
      if (response.status === 201) {
        console.log("Predictions submitted successfully");
        navigate("/");
      }
    } catch (error) {
      console.error("Failed to submit predictions", error);
    }
  };

  function handleClick(
    event: React.MouseEvent,
    game_id: number,
    party_won: "team1" | "team2"
  ) {
    event.preventDefault(); // Prevents the default action of the event
    console.log("SeedTeam was clicked");
    handlePartyClick(game_id, party_won);
  }

  const CustomSeed = ({
    seed,
    breakpoint,
    roundIndex,
    seedIndex,
    isMiddleOfTwoSided,
  }: IRenderSeedProps) => {
    // breakpoint passed to Bracket component
    // to check if mobile view is triggered or not

    // mobileBreakpoint is required to be passed down to a seed
    const Wrapper =
      (roundIndex === 4 || roundIndex === 0) && isMiddleOfTwoSided
        ? SingleLineSeed
        : Seed;
    return (
      <Wrapper mobileBreakpoint={breakpoint} style={{ fontSize: 12 }}>
        <SeedItem>
          <div>
            <SeedTeam
              onClick={(e: React.MouseEvent) =>
                handleClick(e, seed.id as number, "team1")
              }
              style={{
                fontWeight: seed.teams[0]?.isWinner ? "bold" : "normal",
              }}
            >
              {seed.teams[0].seed && `${seed.teams[0].seed} `}
              {seed.teams[0]?.name || " - "}
              {seed.teams[0]?.wins &&
                ` (${seed.teams[0].wins}-${seed.teams[0].losses})`}
            </SeedTeam>
            <SeedTeam
              onClick={(e: React.MouseEvent) =>
                handleClick(e, seed.id as number, "team2")
              }
              style={{
                fontWeight: seed.teams[1]?.isWinner ? "bold" : "normal",
              }}
            >
              {seed.teams[1].seed && `${seed.teams[1].seed} `}
              {seed.teams[1]?.name || " - "}
              {seed.teams[1]?.wins &&
                ` (${seed.teams[1].wins}-${seed.teams[1].losses})`}
            </SeedTeam>
          </div>
        </SeedItem>
      </Wrapper>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Navbar />
      <div className="bracket-builder">
        <h1>Create Your Bracket</h1>
        <p>Click on a team to choose the winner of each game.</p>
        <div className="first-four-container">
          <Bracket
            mobileBreakpoint={0}
            rounds={firstFour}
            renderSeedComponent={CustomSeed}
            swipeableProps={{ enableMouseEvents: true, animateHeight: true }}
            roundTitleComponent={(
              title: React.ReactNode,
              roundIndex: number
            ) => {
              return (
                <div
                  className="pacifico-regular round-title-builder"
                  style={{
                    textAlign: "center",
                    fontSize: "25px",
                  }}
                >
                  {title}
                </div>
              );
            }}
          />
        </div>
        <Bracket
          mobileBreakpoint={0}
          bracketClassName="custom-bracket"
          rounds={rounds}
          renderSeedComponent={CustomSeed}
          swipeableProps={{ enableMouseEvents: true, animateHeight: true }}
          twoSided={true}
          roundTitleComponent={(title: React.ReactNode, roundIndex: number) => {
            return (
              <div
                className="pacifico-regular round-title-builder"
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                }}
              >
                {title}
              </div>
            );
          }}
        />
        <div className="bracket-name">
          <input type="text" placeholder="Enter the bracket name" />
          <input type="button" value="Submit" onClick={handleSubmit} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BracketMaker;
