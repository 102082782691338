import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Prediction, Game, BracketInfo } from "../../types/custom_types";
import Navbar from "./navbar";
import Footer from "./footer";
import Loading from "./Loading/Loading";
import {
  Bracket,
  IRoundProps,
  IRenderSeedProps,
  SingleLineSeed,
  SeedTeam,
  SeedItem,
  Seed,
} from "react-brackets";

const BracketDisplay = () => {
  const [bracket, setBracket] = useState<BracketInfo>();
  const [games, setGames] = useState<Game[]>([]);
  const [predictions, setPredictions] = useState<Prediction[]>([]);
  const [rounds, setRounds] = useState<IRoundProps[]>([]);
  const [didEliminations, setDidEliminations] = useState(false);
  const [loading, setLoading] = useState(true);

  // Use useParams hook to access the route parameters
  const { id } = useParams();

  useEffect(() => {
    const fetchBracket = async () => {
      const response = await axios.get<BracketInfo>(
        `${process.env.REACT_APP_API_URL}/userapi/brackets/${id}/`
      );
      setBracket(response.data);
    };

    fetchBracket();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchGames = async () => {
      const response = await axios.get<Game[]>(
        `${process.env.REACT_APP_API_URL}/userapi/games/`
      );
      setGames(response.data);
    };

    fetchGames();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchPredictions = async () => {
      const response = await axios.get<Prediction[]>(
        `${process.env.REACT_APP_API_URL}/userapi/brackets/${id}/predictions/`
      );
      setPredictions(response.data);
    };

    fetchPredictions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (predictions.length === 0 || games.length === 0) {
      return;
    }

    // Create a map from game id to game
    const gameMap = new Map<number, Game>();
    games.forEach((game) => {
      gameMap.set(game.id, game);
    });

    // Create a map from game id to prediction
    const predictionMap = new Map<number, Prediction>();
    predictions.forEach((prediction) => {
      predictionMap.set(prediction.game_id, prediction);
    });

    // Assuming that the games are in order, we can iterate through the predictions
    // and set the eliminated flag for each team in the prediction. Because the
    // game may not have been played yet, we need to check if the team was eliminated
    // in the previous prediction.
    setPredictions((prev_predictions) => {
      prev_predictions.forEach((prediction) => {
        const game = gameMap.get(prediction.game_id);
        if (!game) {
          return;
        }

        // Check if the game has been played/has a winner
        if (game.winner_name) {
          prediction.team1_eliminated =
            game.winner_name !== prediction.predicted_team1_name;
          prediction.team2_eliminated =
            game.winner_name !== prediction.predicted_team2_name;
          return;
        }

        const previousPrediction1 = predictionMap.get(
          game.previous_game_for_team1_id as number
        );
        const previousPrediction2 = predictionMap.get(
          game.previous_game_for_team2_id as number
        );

        // Check if team1 was team1 or team2 in the previous game
        const team1WasTeam1 =
          previousPrediction1?.predicted_team1_name ===
          prediction.predicted_team1_name;

        prediction.team1_eliminated = previousPrediction1
          ? team1WasTeam1
            ? previousPrediction1.team1_eliminated
            : previousPrediction1?.team2_eliminated
          : false;

        const team2WasTeam1 =
          previousPrediction2?.predicted_team1_name ===
          prediction.predicted_team2_name;

        prediction.team2_eliminated = previousPrediction2
          ? team2WasTeam1
            ? previousPrediction2.team1_eliminated
            : previousPrediction2?.team2_eliminated
          : false;
      });
      return prev_predictions;
    });

    setDidEliminations(true);
  }, [predictions, games]); // eslint-disable-line react-hooks/exhaustive-deps

  // Convert predictions to matches
  useEffect(() => {
    // Wait until both games and predictions are fetched
    if (games.length === 0 || predictions.length === 0 || !didEliminations) {
      return;
    }
    setDidEliminations((prev) => false);

    // Create a map from game id to game
    const gameMap = new Map<number, Game>();
    games.forEach((game) => {
      gameMap.set(game.id, game);
    });

    const calculateTeamScore = (
      predictedWinner: string,
      teamName: string,
      game: Game | undefined
    ): number => {
      if (!game || predictedWinner !== teamName) {
        return 0;
      }
      if (game.winner_name === teamName && game.round > 0) {
        return 2 ** (game.round - 1);
      }
      return 0;
    };

    // Create a map from round to matches
    const roundMap = new Map<number, Prediction[]>();
    predictions.forEach((prediction) => {
      const game = gameMap.get(prediction.game_id) as Game;
      if (game.round === 0) {
        return;
      }

      if (!roundMap.has(game.round)) {
        roundMap.set(game.round, []);
      }
      const round = roundMap.get(game.round);
      round?.push(prediction);
    });

    // Function to map from round number to march madness round name
    const roundTextGenerator = (round: number) => {
      if (round === 1) return "First Round";
      if (round === 2) return "Second Round";
      if (round === 3) return "Sweet 16";
      if (round === 4) return "Elite 8";
      if (round === 5) return "Final 4";
      if (round === 6) return "Championship";
      else return `Round ${round}`;
    };

    // Create a list of rounds from the map
    const roundArr = Array.from(roundMap.entries()).map(
      ([round, prediction]) => {
        return {
          title: roundTextGenerator(round),
          seeds: prediction.map((prediction) => {
            const game = gameMap.get(prediction.game_id);

            // Check if team1 ended up being in the game
            const team1Played =
              game?.team1_name === prediction.predicted_team1_name;

            // Check if team2 ended up being in the game
            const team2Played =
              game?.team2_name === prediction.predicted_team2_name;

            return {
              id: prediction.game_id,
              date: "",
              teams: [
                {
                  id: `${prediction.predicted_team1_name}-${prediction.game_id}`,
                  name: prediction.predicted_team1_name,
                  score: calculateTeamScore(
                    prediction.predicted_winner_name,
                    prediction.predicted_team1_name,
                    game
                  ),
                  pickedAndWon:
                    game?.winner_name &&
                    prediction.predicted_winner_name ===
                      prediction.predicted_team1_name &&
                    game?.winner_name === prediction.predicted_team1_name,
                  pickedAndLost:
                    game?.winner_name &&
                    team1Played &&
                    prediction.predicted_winner_name ===
                      prediction.predicted_team1_name &&
                    game?.winner_name !== prediction.predicted_team1_name,
                  eliminated: prediction.team1_eliminated,
                  chosen:
                    prediction.predicted_winner_name ===
                    prediction.predicted_team1_name,
                  internalRound: game?.round,
                  seed: game?.team1_seed,
                  wins: game?.team1_wins,
                  losses: game?.team1_losses,
                },
                {
                  id: `${prediction.predicted_team2_name}-${prediction.game_id}`,
                  name: prediction.predicted_team2_name,
                  score: calculateTeamScore(
                    prediction.predicted_winner_name,
                    prediction.predicted_team2_name,
                    game
                  ),
                  pickedAndWon:
                    game?.winner_name &&
                    prediction.predicted_winner_name ===
                      prediction.predicted_team2_name &&
                    game?.winner_name === prediction.predicted_team2_name,
                  pickedAndLost:
                    game?.winner_name &&
                    team2Played &&
                    prediction.predicted_winner_name ===
                      prediction.predicted_team2_name &&
                    game?.winner_name !== prediction.predicted_team2_name,
                  eliminated: prediction.team2_eliminated,
                  chosen:
                    prediction.predicted_winner_name ===
                    prediction.predicted_team2_name,
                  internalRound: game?.round,
                  seed: game?.team2_seed,
                  wins: game?.team2_wins,
                  losses: game?.team2_losses,
                },
              ],
            };
          }),
        };
      }
    );

    setRounds(roundArr);
    setLoading(false);
  }, [predictions, games, didEliminations]); // eslint-disable-line react-hooks/exhaustive-deps

  const CustomSeed = ({
    seed,
    breakpoint,
    roundIndex,
    seedIndex,
    isMiddleOfTwoSided,
  }: IRenderSeedProps) => {
    // breakpoint passed to Bracket component
    // to check if mobile view is triggered or not

    // mobileBreakpoint is required to be passed down to a seed
    console.log("starting roundIndex", roundIndex, "seed", seed);
    const Wrapper =
      (roundIndex === 4 || roundIndex === 0) && isMiddleOfTwoSided
        ? SingleLineSeed
        : Seed;
    return (
      <Wrapper mobileBreakpoint={breakpoint} style={{ fontSize: 12 }}>
        <SeedItem>
          <div>
            <SeedTeam
              style={{
                backgroundColor: seed.teams[0]?.pickedAndLost
                  ? "darkred"
                  : seed.teams[0]?.pickedAndWon
                  ? "darkgreen"
                  : seed.teams[0]?.eliminated
                  ? "grey"
                  : "",
                fontWeight: seed.teams[0]?.chosen ? "bold" : "normal",
              }}
            >
              {seed.teams[0]?.seed && seed.teams[0]?.internalRound === 1
                ? `${seed.teams[0]?.seed} `
                : ""}
              {seed.teams[0]?.name || "NO TEAM "}
              {seed.teams[0]?.score ? ` - ${seed.teams[0]?.score}` : ""}
            </SeedTeam>
            <SeedTeam
              style={{
                backgroundColor: seed.teams[1]?.pickedAndLost
                  ? "darkred"
                  : seed.teams[1]?.pickedAndWon
                  ? "darkgreen"
                  : seed.teams[1]?.eliminated
                  ? "grey"
                  : "",
                fontWeight: seed.teams[1]?.chosen ? "bold" : "normal",
              }}
            >
              {seed.teams[1]?.seed && seed.teams[1]?.internalRound === 1
                ? `${seed.teams[1]?.seed} `
                : ""}
              {seed.teams[1]?.name || "NO TEAM "}
              {seed.teams[1]?.score ? ` - ${seed.teams[1]?.score}` : ""}
            </SeedTeam>
          </div>
        </SeedItem>
      </Wrapper>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="bracket-display">
      <Navbar />
      <div className="bracket-header">
        <h1>
          <span className="pacifico-regular">{bracket?.full_name}</span> -{" "}
          Score: {bracket?.score} pts
        </h1>
      </div>
      <Bracket
        mobileBreakpoint={0}
        bracketClassName="custom-bracket"
        rounds={rounds}
        renderSeedComponent={CustomSeed}
        swipeableProps={{ enableMouseEvents: true, animateHeight: true }}
        twoSided={true}
        roundTitleComponent={(title: React.ReactNode, roundIndex: number) => {
          return (
            <div
              className="pacifico-regular round-title"
              style={{
                textAlign: "center",
                fontSize: "25px",
              }}
            >
              {title}
            </div>
          );
        }}
      />
      <Footer />
    </div>
  );
};

export default BracketDisplay;
